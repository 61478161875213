<template>
  <div class="body">
    <el-row :gutter="20">
      <el-upload
        :action= "url"
        :data="{testId:this.$route.query.id}"
        class="upload-btn"
        accept=".xls, .xlsx"
        :on-success="handleAvatarSuccess"
        :show-file-list="false"
      >
      </el-upload>
      <el-button class="poster" type="warning" @click="poster">返回</el-button>
      <p style="color: red">考试时间:{{timeStr}}</p>
      <el-card>
        <div class="tip">单选题</div>
        <template>
          <el-radio-group v-model="radioTwo">
          </el-radio-group>
        </template>
        <div class="content" v-if="radioTwo==1" style="text-align:left">
          <el-row :gutter="100">
            <div v-for="(item,index) in singleChoice" :key="item.id">
              <el-form
                :model="item"
                label-position="right"
                label-width="120px"
                ref="classFrom">
                <el-col :span="24">
                  <el-form-item :label="'题目'+(index+ 1)" prop="">
<!--                    <el-input style="width: 600px" type="textarea" rows="7" v-model="item.question" :disabled="isDisabled"></el-input>-->
                    <p>{{item.question}}</p>
                  </el-form-item>
                  <el-form-item  prop="questionsOptions" v-for="(questionsOptions,childIndex) in item.questionsOptions" :key="childIndex">
                    <el-radio v-model="item.correctAnswer" :label="childIndex" style="margin-left: 5px">{{answeiLable(childIndex)}}</el-radio>
<!--                    <el-input :disabled="isDisabled" style="width: 465px;" v-model="questionsOptions.questionOption" prop="questionsOptions.questionOption"/>-->
                    <span>{{questionsOptions.questionOption}}</span>
                  </el-form-item>
                  <el-divider style="width: 100%"></el-divider>
                </el-col>
              </el-form>
            </div>
          </el-row>
        </div>
      </el-card>
      <el-card>
        <div class="tip">多选题</div>
        <template>
          <el-radio-group v-model="radioTwo">
          </el-radio-group>
        </template>
        <div class="content" v-if="radioTwo==1" style="text-align:left">
          <el-row :gutter="100">
            <div v-for="(item,index) in singleChoide" :key="item.id">
              <el-form
                :model="item"
                label-position="right"
                label-width="120px"
                ref="classFrom" >
                <el-col :span="24">
                  <el-form-item :label="'题目'+(index+ 1)"  prop="">
<!--                    <el-input style="width: 600px" type="textarea" rows="7" v-model="item.question" :disabled="isDisabled"></el-input>-->
                    <p>{{item.question}}</p>
                  </el-form-item>
                  <el-form-item prop="" v-for="(questionsOptions,childIndex) in item.questionsOptions" :key="childIndex">
                    <el-checkbox v-model="questionsOptions.correctAnswer" :true-label="1" :false-label="0" style="margin-left: 5px">{{answeiLable(childIndex)}}</el-checkbox>
<!--                    <el-input style="width: 465px;" v-model="questionsOptions.questionOption" prop="questionsOptions.questionOption" :disabled="isDisabled"/>-->
                    <span style="margin-left: 20px">{{questionsOptions.questionOption}}</span>
                  </el-form-item>
                  <el-divider></el-divider>
                </el-col>
              </el-form>
            </div>
          </el-row>
        </div>
      </el-card>
      <el-card>
        <div class="tip">判断题</div>
        <div class="content" v-for="(item,index) in judgment" :key="item.id" style="text-align:left">
          <el-row :gutter="100" >
            <el-form
              :model="item"
              label-position="right"
              label-width="120px"
              ref="classFrom">
              <el-col :span="24">
                <el-form-item :label="'题目'+(index+ 1)" prop="">
<!--                  <el-input style="width: 600px" type="textarea" rows="7" v-model="item.question" :disabled="isDisabled"/>-->
                  <p>{{item.question}}</p>
                </el-form-item>
                <el-form-item v-for="(questionsOptions,childIndex) in item.questionsOptions" :key="childIndex">
                  <el-radio-group v-model="questionsOptions.correctAnswer">
                    <el-radio :label="1">正确</el-radio>
                    <el-radio :label="0">错误</el-radio>
                  </el-radio-group>
                </el-form-item>
              </el-col>
            </el-form>
          </el-row>
          <el-divider></el-divider>
        </div>
      </el-card>
      <el-card>
        <div class="tip">填空题</div>
        <div class="content" v-for="(item,index) in filling" :key="item.id" style="text-align:left">
          <el-row :gutter="100" >
            <el-form
              :model="item"
              label-position="right"
              label-width="120px"
              ref="classFrom">
              <el-col :span="24">
                <el-form-item :label="'题目'+(index+ 1)" prop="">
<!--                  <el-input :disabled="isDisabled" style="width: 600px" type="textarea" rows="7" v-model="item.question" @change="ChangeFilling(item,index)"/>-->
                  <p>{{item.question}}</p>
                </el-form-item>
                <el-form-item label="答案" v-for="(questionsOptions,childIndex) in item.questionsOptions" :key="childIndex">
                  <el-input  style="width: 600px"  v-model="questionsOptions.questionOption" prop="questionsOptions.questionOption" type="textarea" rows="5"/>
                </el-form-item>
              </el-col>
            </el-form>
          </el-row>
          <el-divider></el-divider>
        </div>
      </el-card>
      <el-button type="primary" style="margin-top: 12px;" @click="addStudentTestInfo()">提交</el-button>
    </el-row>
  </div>
</template>
<script>
  import { detailStuTest, addStudentTestInfo } from '@/api/test.js'
  import { setCookie, getCookie } from '@/utils/base'
  import { getUrl } from '@/utils/request.js'
  export default {
    name: 'Examination',
    data() {
      return {
        stuOptionsList: [],
        tests: '',
        classId: '',
        ClassIdname: '',
        totalTime: '',
        timeStr: '',
        d: '',
        h: '',
        m: '',
        s: '',
        isDisabled: false,
        listone: [],
        radio: [],
        quesData: [{
          testId: '',
          questionsData: []
        }],
        url: getUrl() + '/bh/test/getQuestionsListToExcel',
        Examser: [{
          esesse: '',
          radio: ''
        }],
        testId: this.$route.query.id,
        list: [],
        judgment: [],
        short: [],
        filling: [],
        singleChoice: [],
        singleChoide: [],
        listTwo: [],
        active: 0,
        classFrom: {
          duration: '',
          value1: ''
        },
        radioTwo: 1,
        timer: null // 定时器名称
      }
    },
    // 最后在beforeDestroy()生命周期内清除定时器
    beforeDestroy() {
        clearInterval(this.timer)
        this.timer = null
    },
    methods: {
      // 定时器
      countTime: function() {
        this.timer = setInterval(() => {
            const leftTime = this.totalTime
            if (leftTime >= 0) {
              const h = Math.floor((leftTime / 60 / 60) % 24)
              const m = Math.floor((leftTime / 60) % 60)
              const s = Math.floor((leftTime) % 60)
              this.timeStr = h + '小时' + m + '分钟' + s + '秒'
              this.totalTime--
              setCookie('testId' + this.testId + 'totalTime', leftTime)
              if (this.totalTime === 0) {
                 this.$alert('考试时间到', '考试结束', {
                  confirmButtonText: '确定',
                  callback: action => {
                    this.addStudentTestInfo()
                  }
                })
              }
            }
        }, 1000)
      },
      not() {
      },
      detailStuTest() {
        if (this.testId) {
          detailStuTest(this.testId).then(resp => {
            const tests = resp.data.data.id
            this.testId = tests
            const ClassIdname = resp.data.data.classId
            this.classId = ClassIdname
            console.log(this.classId)
            console.log(tests)
            const questionsData = resp.data.data.questionsData
            questionsData.forEach(item => {
              if (item.questionType === 1) {
                this.singleChoice.push(item)
              }
              if (item.questionType === 2) {
                this.singleChoide.push(item)
              }
              if (item.questionType === 4) {
                this.judgment.push(item)
              }
              if (item.questionType === 3) {
                this.filling.push(item)
              }
            })
            const status = resp.data.data.testStatus
            if (status === 3 || status === 4) {
              // this.isDisabled = true
            }
            const leftTime = resp.data.data.totalTime * 60
            if (getCookie('testId' + this.testId + 'totalTime') > 0) {
              this.totalTime = getCookie('testId' + this.testId + 'totalTime')
            } else {
              this.totalTime = leftTime
              setCookie('testId' + this.testId + 'totalTime', leftTime)
            }
          }, 1000)
          this.countTime()
          if (this.leftTime === 0) {
          }
        }
      },
      addStudentTestInfo() {
        const stuOptionsList = []
        this.singleChoice.forEach(item => {
          item.questionsOptions.forEach(function (item1, index) {
            if (index === item.correctAnswer) {
              item1.correctAnswer = 1
            } else {
              item1.correctAnswer = 0
            }
          })
          stuOptionsList.push(item)
        })
        this.singleChoide.forEach(item => {
          item.questionType = 2
          stuOptionsList.push(item)
        })
        this.filling.forEach(item => {
          item.questionType = 3
          stuOptionsList.push(item)
        })
        this.judgment.forEach(item => {
          item.questionType = 4
          stuOptionsList.push(item)
        })
        const data = {
          id: this.testId,
          classId: this.classId,
          questionsData: stuOptionsList
        }
        addStudentTestInfo(data).then(resp => {
          if (resp.data.code === '0') {
            this.$notify({
              title: '考试结束',
              message: '恭喜，提交考试成功。',
              type: 'success'
            })
          }
          this.$router.push({ path: '/Examinations' })
        })
      },
      handleAvatarSuccess (res, file) {
        console.log(res)
        this.$notify({
          title: '导入成功',
          type: 'success'
        })
      },
      answeiLable(index) {
        if (index === 0) {
          return 'A.'
        } else if (index === 1) {
          return 'B.'
        } else if (index === 2) {
          return 'C.'
        } else if (index === 3) {
          return 'D.'
        } else if (index === 4) {
          return 'E.'
        } else if (index === 5) {
          return 'F.'
        } else if (index === 6) {
          return 'G.'
        }
      },
      delSingleChoice(index) {
        this.singleChoice.splice(index, 1)
      },
      delSingleChoide(index) {
        this.singleChoide.splice(index, 1)
      },
      deljudgment(index) {
        this.judgment.splice(index, 1)
      },
      delfilling(index) {
        this.filling.splice(index, 1)
      },
      ChangeFilling(item, row) {
        const question = item.question
        const questionList = []
        for (let i = 0; i < question.length; i++) {
          questionList.push(question.charAt(i))
        }
        let index = 0
        let def = true
        questionList.forEach(item => {
          if (item === '_') {
            if (def) {
              index++
              def = false
            }
          } else {
            def = true
          }
        })
        item.questionsOptions = []
        for (let i = 0; i < index; i++) {
          item.questionsOptions.push({ questionOption: '', correctAnswer: (i + 1) })
        }
        console.log(questionList)
        // this.filling.push(row, 1, item)
      },
      deLete(item, index, childrenIndex) { // 删除选项
        item.questionsOptions.splice(childrenIndex, 1)
        this.singleChoice.splice(index, 1, item)
      },
      poster() {
        this.$router.push({ path: '/Examinations' })
      }
      // updataTestAndQuestionsInfo() {
      //   const questionsData = []
      //   this.singleChoice.forEach(item => {
      //     item.questionsOptions.forEach(function (item1, index) {
      //       if (index === item.correctAnswer) {
      //         item1.correctAnswer = 1
      //       } else {
      //         item1.correctAnswer = 0
      //       }
      //     })
      //     item.questionType = 1
      //     questionsData.push(item)
      //   })
      //   this.singleChoide.forEach(item => {
      //     item.questionType = 2
      //     questionsData.push(item)
      //   })
      //   this.filling.forEach(item => {
      //     item.questionType = 3
      //     questionsData.push(item)
      //   })
      //   this.judgment.forEach(item => {
      //     item.questionType = 4
      //     questionsData.push(item)
      //   })
      //   const quesData = {
      //     id: this.$route.query.id,
      //     questionsData: questionsData
      //   }
      //   console.log(quesData)
      //   updataTestAndQuestionsInfo(quesData).then(resp => {})
      // }
    },
    mounted() {
      this.detailStuTest() // 初始化考试信息
      this.isDisabled = true
    }
  }
</script>

<style scoped>
  .body{padding: 20px 30px;  background-color: #fff;}
  .content{margin-top: 25px;}
  .info-explain span{ margin-right: 25px;}
  .tip{text-align: left;padding: 8px 16px;
    background-color: #ecf8ff;
    border-radius: 4px;
    border-left: 5px solid #50bfff;
    margin: 20px 0;}
  .poster{position: absolute; left: 120px; top: 5px}
  .upload-btn{position: absolute; left: 20px; top: 5px}
</style>
